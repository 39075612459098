//client/src/App.js

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useRoutes } from './routes';
import { AuthProvider, useAuth } from './context/AuthContext';
import { Navbar } from './components/Navbar';
import { Loader } from './components/Loader';
import 'antd/dist/reset.css';

// Компонент для логіки додатку
const AppContent = () => {
    const { token, login, logout, userId, role, ready, isAuthenticated } = useAuth();
    const routes = useRoutes(isAuthenticated);

    console.log("AppContent rendered");
    console.log("Token:", token);
    console.log("UserId:", userId);
    console.log("Ready:", ready);
    console.log("IsAuthenticated:", isAuthenticated);
    console.log("Role:", role);

    if (!ready) {
        return <Loader />;
    }

    return (
        <>
            {isAuthenticated && <Navbar />}
            <div className="container">
                {routes}
            </div>
        </>
    );
};

// Створюємо маршрути з future flags
const router = createBrowserRouter(
    [
        // Авторизовані маршрути
        {
            path: "/",
            element: <AppContent />,
            children: [
                { path: "home", element: <Navbar /> },
                { path: "survey", element: <Loader /> },
            ],
        },
    ],
    {
        future: {
            v7_startTransition: true,
            v7_relativeSplatPath: true,
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_skipActionErrorRevalidation: true,
        },
    }
);

// Головний компонент додатку
const App = () => {
    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    );
};

export default App;
