// home/servers/feedcraft/client/src/pages/SurveyFlow.js

import React, { useState, useEffect } from 'react';
import { Form, Button, Radio, Input, Card, Progress, Checkbox, Select, Spin, Slider, InputNumber, Row, Col } from 'antd';
import axios from 'axios';
import { catQuestions } from '../components/questions/CatQuestions';
import { dogQuestions } from '../components/questions/DogQuestions';
import { calculateCatRecommendations } from '../controllers/recommendation/catRecommendationLogic';
import { calculateDogRecommendations } from '../controllers/recommendation/dogRecommendationLogic';
import SurveyResults from './SurveyResults';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
console.log('Axios baseURL:', axios.defaults.baseURL);

const CustomSelect = ({ value, onChange, options }) => {
  return (
    <Select
      value={value}
      style={{ width: '100%' }}
      onChange={onChange}
      options={options.map(option => ({
        value: option.value,
        label: option.label,
      }))}
    />
  );
};

const CustomMultipleSelect = ({ value, onChange, options }) => {
  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      options={options.map(option => ({
        value: option.value,
        label: option.label,
      }))}
      openAnimation={false}
      dropdownRender={menu => <div style={{ animation: 'none' }}>{menu}</div>}
    />
  );
};

const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const IntegerStep = ({ value, onChange, petType }) => {
  const [inputValue, setInputValue] = useState(value || (petType === 'dog' ? 1 : 0.5));

  const handleChange = (newValue) => {
    const validValue = petType === 'dog' ? Math.round(newValue) : newValue;
    setInputValue(validValue);
    onChange(validValue);
  };

  const isMobile = window.innerWidth <= 768;

  const handleIncrement = () => {
    handleChange(inputValue + (petType === 'dog' ? 1 : 0.5));
  };

  const handleDecrement = () => {
    handleChange(inputValue - (petType === 'dog' ? 1 : 0.5));
  };

  const max = petType === 'dog' ? 80 : 10;
  const min = petType === 'dog' ? 1 : 0.5;
  const step = petType === 'dog' ? 1 : 0.5;

  return (
    <Row>
      <Col span={isMobile ? 24 : 12}>
        <Slider
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
          value={typeof inputValue === 'number' ? inputValue : min}
          tooltip={{ formatter: value => `${value} кг` }}
        />
      </Col>
      <Col span={isMobile ? 0 : 4}>
        {!isMobile && (
          <InputNumber
            min={min}
            max={max}
            step={step}
            style={{ margin: '0 16px' }}
            value={inputValue}
            onChange={handleChange}
          />
        )}
      </Col>
      {isMobile && (
        <Col span={24} style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button onClick={handleDecrement} disabled={inputValue <= min}>
            -
          </Button>
          <span style={{ margin: '0 10px' }}>{inputValue} кг</span>
          <Button onClick={handleIncrement} disabled={inputValue >= max}>
            +
          </Button>
        </Col>
      )}
    </Row>
  );
};

const SurveyFlow = () => {
  const [petType, setPetType] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [completed, setCompleted] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const getDynamicQuestionText = (question) => {
    if (question && question.question.includes('(name)') && answers.name) {
      return question.question.replace('(name)', answers.name.value);
    }
    return question ? question.question : '';
  };

  const questions = petType === 'cat' ? catQuestions : petType === 'dog' ? dogQuestions : [];

  // Логіка для управління disabled станом для питання outdoorAccess
  const isOutdoorAccessDisabled = (optionValue) => {
    const selectedOptions = answers.outdoorAccess?.value || [];
    const isNoSelected = selectedOptions.includes('no');
    const isOtherSelected = selectedOptions.length > 0 && !isNoSelected;
    return optionValue === 'no' ? isOtherSelected : isNoSelected;
  };

  // Додаємо глобальні стилі для компонента Select через useEffect
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .ant-progress-steps-item {
        height: 2px !important;
        margin: 0 2px !important;
        transition: none !important;
        min-height: 2px !important;
        max-height: 2px !important;
      }
      .ant-progress-bg {
        height: 2px !important;
        transition: none !important;
        min-height: 2px !important;
        max-height: 2px !important;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);   

  useEffect(() => {
    const stepItems = document.querySelectorAll('.ant-progress-steps-item');
    const stepCount = questions.length;
    const padding = 2; // Відступ між кроками

    if (stepItems.length > 0) {
      // Розраховуємо ширину з урахуванням відступів
      const stepWidth = `calc(${100 / stepCount}% - ${padding * 2}px)`;

      stepItems.forEach((item) => {
        item.style.width = stepWidth;
        item.style.height = '2px'; // Фіксована висота для стабільності
        item.style.margin = `0 ${padding}px`; // Додаємо відступ між кроками
        item.style.minHeight = '2px'; // Мінімальна висота
        item.style.maxHeight = '2px'; // Максимальна висота
        item.style.transition = 'none'; // Вимикаємо анімацію
      });
    }
  }, [currentQuestionIndex, answers, questions.length]);

  const handleNext = async () => {
    let nextIndex = currentQuestionIndex + 1;

    while (questions[nextIndex]?.condition && !questions[nextIndex].condition(answers)) {
      nextIndex += 1;
      if (nextIndex >= questions.length) break;
    }

    if (nextIndex < questions.length) {
      setCurrentQuestionIndex(nextIndex);
    } else {
      console.log('Зібрані відповіді:', answers);

      const formattedAnswers = Object.keys(answers).map(key => ({
        questionValue: answers[key]?.questionValue,
        value: answers[key]?.value,
      }));

      let characteristics;
      if (petType === 'cat') {
        characteristics = calculateCatRecommendations(answers);
      } else if (petType === 'dog') {
        characteristics = calculateDogRecommendations(answers);
      }

      try {
        const responseSaveAnswers = await axios.post('/api/survey/response', {
          answers: formattedAnswers,
          petType,
          characteristics
        });

        const savedResponseId = responseSaveAnswers.data && responseSaveAnswers.data.id;

        const endpoint = petType === 'cat'
          ? 'https://feedcraft.space/api/localProductsCat'
          : 'https://feedcraft.space/api/localProductsDog';

        const responseFilteredProducts = await axios.post(endpoint, { characteristics, petType });
        const receivedProducts = responseFilteredProducts.data.products;

        if (receivedProducts.length === 0) {
          throw new Error('Продукти не знайдено');
        }

        setProducts(receivedProducts);

        if (savedResponseId) {
          await axios.put(`/api/survey/response/${savedResponseId}`, { products: receivedProducts });
        }

        setCompleted(true);
      } catch (error) {
        console.error('Помилка при отриманні продуктів або збереженні відповідей:', error.message);
        setError('Помилка при отриманні продуктів або збереженні відповідей. Будь ласка, спробуйте ще раз.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      let prevIndex = currentQuestionIndex - 1;
      while (questions[prevIndex]?.condition && !questions[prevIndex].condition(answers)) {
        prevIndex -= 1;
        if (prevIndex < 0) break;
      }
      setCurrentQuestionIndex(prevIndex);
    }
  };

  const handleChange = (e) => {
    const value = e?.target?.value || '';

    if (questions[currentQuestionIndex]?.questionValue === 'email') {
      if (!validateEmail(value)) {
        setEmailError('Некоректний email');
        setIsEmailValid(false);
      } else {
        setEmailError(null);
        setIsEmailValid(true);
      }
    }

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[currentQuestionIndex]?.questionValue]: {
        questionValue: questions[currentQuestionIndex]?.questionValue,
        value: value
      }
    }));
  };

  const handleCheckboxChange = (checkedValues) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[currentQuestionIndex]?.questionValue]: {
        questionValue: questions[currentQuestionIndex]?.questionValue,
        value: checkedValues
      }
    }));
  };

  const handleMultipleSelectChange = (selectedValues) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[currentQuestionIndex]?.questionValue]: {
        questionValue: questions[currentQuestionIndex]?.questionValue,
        value: selectedValues,
      },
    }));
  };

  const isNextButtonDisabled = (question) => {
    const answer = answers[question.questionValue]?.value || [];
    if (question.type === 'checkbox') {
      return answer.length === 0;
    }

    if (question.questionValue === 'email' && !isEmailValid) {
      return true;
    }

    return !answers[question.questionValue]?.value;
  };

  const renderFirstQuestion = () => (
    <Card title="Опитувальник" bordered={false} style={{ width: '100%' }}>
      <Form layout="vertical">
        <Form.Item label="Який у вас хвостик?">
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div
              onClick={() => setPetType('cat')}
              style={{
                flexBasis: '45%',
                marginBottom: '16px',
                textAlign: 'center',
                border: petType === 'cat' ? '2px solid #1890ff' : '1px solid rgb(217, 217, 217)',
                borderRadius: '8px',
                padding: '8px',
                cursor: 'pointer',
              }}
            >
              <img
                src="https://homefood.ua/content/images/30/0dlia-kartynok_2-78509377797457_+98c4c6c014.png"
                alt="Котик"
                style={{ width: '100%', height: 'auto', display: 'block', margin: '0 auto' }}
              />
              <div>Котик</div>
            </div>
            <div
              onClick={() => setPetType('dog')}
              style={{
                flexBasis: '45%',
                marginBottom: '16px',
                textAlign: 'center',
                border: petType === 'dog' ? '2px solid #1890ff' : '1px solid rgb(217, 217, 217)',
                borderRadius: '8px',
                padding: '8px',
                cursor: 'pointer',
              }}
            >
              <img
                src="https://homefood.ua/content/images/30/0dlia-kartynok_2-64573032813260_+9391af71c5.png"
                alt="Песик"
                style={{ width: '100%', height: 'auto', display: 'block', margin: '0 auto' }}
              />
              <div>Песик</div>
            </div>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );

  const renderQuestion = (question) => {
    if (question.condition && !question.condition(answers)) {
      handleNext();
      return null;
    }

    const dynamicQuestionText = getDynamicQuestionText(question);

    return (
      <Card title="Опитувальник" bordered={false}>
        <Form layout="vertical" key={currentQuestionIndex}>
          <Form.Item label={dynamicQuestionText}>
            {question && question.type === 'text' && (
              <>
                <Input
                  placeholder="Ваша відповідь"
                  onChange={handleChange}
                  value={answers[question.questionValue]?.value || ''}
                />
                {question.questionValue === 'email' && emailError && (
                  <span style={{ color: 'red' }}>{emailError}</span>
                )}
              </>
            )}
            {question && question.type === 'radio' && question.options[0]?.image ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {question.options && question.options.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => handleChange({ target: { value: option.value } })}
                    style={{
                      flexBasis: '45%',
                      marginBottom: '16px',
                      textAlign: 'center',
                      border: answers[question.questionValue]?.value === option.value
                        ? '2px solid #1890ff'
                        : '1px solid rgb(217, 217, 217)',
                      borderRadius: '8px',
                      padding: '8px',
                      cursor: 'pointer'
                    }}
                  >
                    {option.image && (
                      <img
                        src={option.image}
                        alt={option.label}
                        style={{ width: '100%', height: 'auto', display: 'block', margin: '0 auto' }}
                      />
                    )}
                    <div>{option.label}</div>
                  </div>
                ))}
              </div>
            ) : (
              question && question.type === 'radio' && (
                <Radio.Group
                  onChange={handleChange}
                  value={answers[question.questionValue]?.value || ''}
                  style={{
                    display: 'flex',
                    flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                  }}
                >
                  {question.options && question.options.map((option, index) => (
                    <Radio key={index} value={option.value} style={{ flexBasis: window.innerWidth <= 768 ? '100%' : '45%', marginBottom: '8px' }}>
                      {option.label}
                    </Radio>
                  ))}
                </Radio.Group>
              )
            )}
            {question && question.type === 'checkbox' && (
              <Checkbox.Group
                onChange={handleCheckboxChange}
                value={answers[question.questionValue]?.value || []}
                style={{
                  display: 'flex',
                  flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                }}
              >
                {question.options && question.options.map((option, index) => {
                  const isNoneSelected = answers[question.questionValue]?.value?.includes('no');
                  const isOtherSelected = answers[question.questionValue]?.value?.length > 0 && !isNoneSelected;

                  return (
                    <Checkbox
                      key={index}
                      value={option.value}
                      disabled={(option.value !== 'no' && isNoneSelected) || (option.value === 'no' && isOtherSelected)}
                      style={{ flexBasis: window.innerWidth <= 768 ? '100%' : '45%', marginBottom: '8px' }}
                    >
                      {option.label}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            )}
            {question && question.type === 'select' && (
              <CustomSelect
                value={answers[question.questionValue]?.value || ''}
                onChange={(value) => handleChange({ target: { value } })}
                options={question.options}
              />
            )}
            {question && question.type === 'slider' && (
              <IntegerStep
                value={answers[question.questionValue]?.value || 1}
                onChange={(value) => handleChange({ target: { value } })}
                petType={petType}
              />
            )}
            {question && question.type === 'multipleSelect' && (
              <CustomMultipleSelect
                value={answers[question.questionValue]?.value || []}
                onChange={handleMultipleSelectChange}
                options={question.options}
              />
            )}
          </Form.Item>
          <Progress
            percent={Math.round(((currentQuestionIndex + 1) / questions.length) * 100)}
            steps={questions.length}
            showInfo={false}
            style={{
              marginBottom: '24px',
              width: '100%',
              height: '2px',
              transition: 'none',
            }}
            className="custom-progress-bar"
          />
          <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Button onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
              Попереднє
            </Button>
            <Button
              type="primary"
              onClick={handleNext}
              disabled={isNextButtonDisabled(question)}
            >
              Наступне
            </Button>
          </div>
        </Form>
      </Card>
    );
  };

  const renderResults = () => (
    <SurveyResults products={products} answers={answers} petType={petType} />
  );

  return (
    <div style={{ maxWidth: completed ? '1000px' : '600px', margin: '0 auto', padding: '20px' }}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : !petType ? (
        renderFirstQuestion()
      ) : completed ? (
        renderResults()
      ) : (
        renderQuestion(questions[currentQuestionIndex])
      )}
    </div>
  );
};

export default SurveyFlow;
